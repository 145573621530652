<template class="bg-white h-full">
  <div class="bg-white h-full">
    <div class="h-44 bg-gray-800 flex items-center justify-center mt-9 lg:mt-0">
      <h1 class="font-bold lg:text-4xl text-xl text-white">
        {{ $t('terms_of_use') }}
      </h1>
    </div>
    <div class="text-center lg:ml-44 lg:mr-44 bg-gray-100 p-4 mx-4 lg:mx-0">
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        {{ $t("cgu_intro") }}<br /> {{ $t("cgu_access") }}<br /> {{ $t("cgu_acceptance") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
       {{ $t("article_1_legal_notice") }}
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        {{ $t("legal_notice_content") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
       {{ $t("access_to_site") }}
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
      {{ $t("free_access_services") }}
        <br />{{ $t("tutorial") }}<br />
        {{ $t("course") }}<br />
        {{ $t("exercises") }}<br />
        {{ $t("video") }}<br />
        {{ $t("forum") }}<br />
       {{ $t("site_access") }} <br />{{ $t("non_member_access") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
       {{ $t("article_3_title") }}
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
       {{ $t("data_collection_info") }} <br />{{ $t("user_rights") }}
        <br />
        {{ $t("contact_email") }} <br />{{ $t("contact_form") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        {{ $t("article_4_title") }}
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        {{ $t("intellectual_property_content") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        ARTICLE 5 : RESPONSABILITE
      </h1>
      <p class="text-base text-gray-700 mt-4 text-left text-lg">
        {{ $t("responsibility_info") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
        {{ $t("article_6_title") }}
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        {{ $t("hypertext_links_info") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">{{ $t("article_7_title") }}</h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        {{ $t("cookie_info") }}
      </p>
      <h1 class="font-bold text-2xl text-gray-700 mt-4">
       {{ $t("article_8_title") }}
      </h1>
      <p class="text-base text-gray-700 mt-9 text-left text-lg">
        {{ $t("article_8_content") }}
      </p>
    </div>

    <div class="h-6"></div>
  </div>
</template>
  
<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>
  
<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

input:checked {
  background-color: #22c55e; /* bg-green-500 */
}

input:checked ~ span:last-child {
  --tw-translate-x: 1.75rem; /* translate-x-7 */
}
</style>
  