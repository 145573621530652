
<script>
/* global grecaptcha */
import axios from "axios";
//import TheModal from "../components/TheModal.vue";

import TokenService from "../services/storage.service";
import ApiService from "../services/api.service";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
import AlertComponent from "../components/AlertComponent.vue";
export default {
  name: "LoginForm",
  components: {
    spiner,
    AlertComponent,
   
    //TheModal,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        // local: null,
      },
      rcapt_sig_key: "6LdXFwwqAAAAADBJ53eIUzO87nBYHTt8rUWk5D5Y",
        rcapt_id: 0 ,
      alert: {
        type: "",
        message: "",
      },
      showAlert: false,
      processing: false,
        isLoading: false,
          showDropdown: false,
          showPassword: false,
        selectedLang: "FR", 
    };
  },
 created() {
    this.selectedLang = TokenService.getLang();
    //this.profile();
  },
 
  methods: {
     toggleLanguageDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    setLanguage(lang) {
      this.selectedLang = lang;
      this.showDropdown = false;
      this.translatePage();
    },
    async translatePage() {
      TokenService.saveLang(this.selectedLang);
      window.location.reload();
      this.newTranslate();
    },
    async newTranslate() {
      this.$i18n.locale = TokenService.getLang();
    },
    async validateCaptcha() {
  const response = grecaptcha.getResponse(this.rcapt_id);
  
  if (!response) {
    this.showAlert = true;

    // Vérification de la langue et affichage du message approprié
    const lang = TokenService.getLang();
    const captchaErrorMessage = lang === 'fr' 
      ? "Veuillez vérifier que vous n'êtes pas un robot." 
      : "Please verify that you are not a robot.";

    this.alert.message = captchaErrorMessage;

    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  } else {
    await this.login();
  }
}
,
   async login() {
  try {
    this.isLoading = true;
    const response = await axios.post("/api/auth/login", this.form);
    
    if (response.data.access_token) {
      TokenService.saveToken(response.data.access_token);
      ApiService.setHeader();
      this.isLoading = false;

      // Vérification de la langue et affichage du message approprié
      const lang = TokenService.getLang();
      const successMessage = lang === 'fr' 
        ? "Merci, pour votre connexion." 
        : "Thank you for logging in.";

      new Noty({
        type: "success",
        layout: "topRight",
        text: successMessage,
        timeout: 5000,
      }).show();

      this.form = {};
      this.$router.push("/");
      
    } else {
      // Gestion d'une réponse sans jeton d'accès
      this.isLoading = false;
      this.showAlert = true;
      
      const lang = TokenService.getLang();
      const errorMessage = lang === 'fr' 
        ? "Erreur lors de la connexion. Veuillez réessayer plus tard." 
        : "Error during login. Please try again later.";

      this.alert.message = errorMessage;
      setTimeout(() => {
        this.showAlert = false;
      }, 5000);
    }
  } catch (error) {
    // Gestion des erreurs HTTP
    this.isLoading = false;

    const lang = TokenService.getLang();
    
    if (error.response && error.response.status === 422) {
      this.showAlert = true;
      
      const invalidCredentialsMessage = lang === 'fr' 
        ? "Adresse e-mail ou mot de passe incorrect." 
        : "Incorrect email or password.";

      this.alert.message = invalidCredentialsMessage;

    } else {
      this.showAlert = true;
      
      const generalErrorMessage = lang === 'fr' 
        ? "Quelque chose s'est mal passé. Merci d'essayer plus tard." 
        : "Something went wrong. Please try again later.";

      this.alert.message = generalErrorMessage;
    }

    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }
},

     togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    initializeRecaptcha() {
      grecaptcha.ready(() => {
        this.rcapt_id = grecaptcha.render(document.querySelector('.g-recaptcha'), { sitekey: this.rcapt_sig_key });
      });
    },
    
  },
  mounted() {
    this.initializeRecaptcha();
  },
  
};
</script>

<template class="">
  <div class="p-6 space-y-4 md:space-y-6 sm:p-8 lg:w-1/3 mx-auto my-auto mt-4 mb-4 box-shadow-all-sides">
  <div v-show="showAlert">
        <AlertComponent :content="alert.message" type-alert="error" />
      </div>
      <div class="flex">
    <h1 class="font-bold text-xl text-white">{{ $t('login') }}</h1>
      <div class="custom-language-selector ml-auto">
            <div @click="toggleLanguageDropdown" class="selected-language">
              <img
                :src="
                  selectedLang === 'fr'
                    ? require('../assets/27099.jpg')
                    : require('../assets/18166.jpg')
                "
                alt="selected language"
              />
            </div>
            <ul v-if="showDropdown" class="language-dropdown">
              <li @click="setLanguage('en')">
                <img src="../assets/18166.jpg" alt="English" />
              </li>
              <li @click="setLanguage('fr')">
                <img src="../assets/27099.jpg" alt="Français" />
              </li>
            </ul>
          </div>
          </div>
    <form
      method="POST"
      action="#"
      class="space-y-4 md:space-y-6"
       @submit.prevent="validateCaptcha"
    >
      <div class="">
        <label class="block font-bold text-sm text-white text-left">Email</label>
        <div class="mt-1">
          <input
          class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
            type="email"
            autocomplete="current-email"
            v-model="form.email"
            required
          />
        </div>
      </div>
      
     <div class="space-y-1 mt-5">
  <label class="block font-bold text-sm text-white text-left">Password</label>
  <div class="mt-1 relative"> <!-- Ajout de relative ici -->
    <input
      :type="showPassword ? 'text' : 'password'"
      class="block w-full p-2 pr-10 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
      autocomplete="current-password"
      v-model="form.password"
      required
    />
    <button
      type="button"
      @click="togglePasswordVisibility"
      class="absolute inset-y-0 right-0 pr-3 flex items-center"
    >
      <svg
        v-if="showPassword"
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-gray-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          d="M10 3a7 7 0 015.464 2.536A7 7 0 0118 10a7 7 0 01-2.536 5.464A7 7 0 0110 18a7 7 0 01-5.464-2.536A7 7 0 012 10a7 7 0 012.536-5.464A7 7 0 0110 3zm0 2a5 5 0 00-5 5 5 5 0 005 5 5 5 0 005-5 5 5 0 00-5-5zm0 2a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"
        />
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        class="h-5 w-5 text-gray-500"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
        />
      </svg>
    </button>
  </div>
</div>

      <div class="flex items-center justify-between">
        <div class="hidden sm:flex sm:items-start">
          <div class="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              class="rounded border-gray-300 mt-1 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div class="ml-1 text-sm">
            <label for="remember" class="text-sm font-medium text-white dark:text-gray-300 ">{{ $t('remember_me') }}
            </label>
          </div>
        </div>

         <router-link to="/auth/password"
          class=" bg-blue-500 rounded-full px-4 text-sm font-medium text-black hover:underline dark:text-primary-500"
        >
          {{ $t('forgot_password') }}
         </router-link>
      </div>
      <div class="g-recaptcha" :data-sitekey="rcapt_sig_key"></div>
      <button
        type="submit"
        class="bg-blue-500 text-white px-8 py-2 focus:outline-none poppins rounded-full mt-24 transform transition duration-300 hover:scale-105"
      >
        <span v-if="isLoading">
          <spiner />
        </span>
        <spam v-else class="ml-2 flex">
          <span> {{ $t('logins') }}</span>
        </spam>
      </button>
    </form>
    <p class="text-sm text-white ">
      {{ $t('no_account') }}
      <router-link to="/auth/register"
        
        class="bg-blue-500 rounded-full px-4 font-sans text-black ml-4 hover:underline dark:text-primary-500"
      >
        {{ $t('register_here') }}</router-link
      >
    </p>
  </div>

</template>
<style scoped>
  .box-shadow-all-sides {
    box-shadow: 1px 1px 8px rgba(0.2, 0.2, 0.2, 0.2); /* Ajustez les valeurs en conséquence */
  }
  .language-selector {
  display: flex;
  align-items: center;
}

.language-selector select {
  margin-left: 10px;
  padding: 5px;
  font-size: 16px;
  background-color: #f0f0f0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.language-selector option {
  background-color: white;
  font-size: 16px;
  padding: 10px;
}
.custom-language-selector {
  position: relative;
  display: inline-block;
}

.selected-language img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.language-dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  padding: 0;
  list-style: none;
  width: 30px;
}

.language-dropdown li {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.language-dropdown li img {
  width: 80px;
  height: 20px;
  margin-right: 8px;
}

.language-dropdown li:hover {
  background-color: #f0f0f0;
}
</style>