<template>
  <div class="min-h-screen">
    <div class="h-28 bg-gray-800 flex items-center justify-center">
      <h1
        class="text-blue-500 lg:text-2xl text-xl mt-4 font-mono md:mx-auto text-center"
      >
        {{ section.name }}
      </h1>
    </div>
    <div class="lg:mx-36 mx-9">
      <p
        class="text-gray-700 lg:text-2xl text-sm font-serif mt-2 lg:ml-12 lg:text-left text-justify"
      >
        {{ section.description }}
      </p>
      <h1
        class="lg:text-2xl text-xl mt-4 lg:text-left lg:ml-12 font-serif md:mx-auto md:text-center"
      >
         {{ $t('status') }}: <span class="text-green-500"> {{ section.status }}</span>
      </h1>
      <p
        class="text-gray-700 lg:text-lg text-sm font-serif mt-4 lg:ml-12 lg:text-left"
      >
        {{ $t('training_durations') }}

        <span class="text-blue-500 font-mono">
          {{ section.date }} a {{ section.date_fin }} ({{ section.gmt }}).</span
        >
      </p>
      <p
        class="text-gray-700 lg:text-lg text-sm font-serif mt-4 lg:ml-12 lg:text-left flex"
      >
        <span> {{ $t('access_training') }}</span>
        <a :href="section.meet_url" target="_blank" class="block ml-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-9 h-9 text-blue-500"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
            />
          </svg>
        </a>
      </p>
      <h1
        class="lg:text-2xl text-xl mt-4 lg:text-left lg:ml-12 font-serif md:mx-auto md:text-center"
      >
        {{ $t('associated_files') }}
      </h1>
      <div v-for="document in documents" :key="document.id">
        <p
          class="text-gray-700 lg:text-lg text-sm font-serif mt-4 lg:ml-12 lg:text-left flex"
        >
          <span> {{ document.name }} :</span>
          <a :href="document.document_url" target="_blank" class="block ml-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="h-6 w-6 text-blue-500"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
              />
            </svg>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TokenService from "../services/storage.service";
// import Noty from "noty";
// import "noty/lib/noty.css";
// import "noty/lib/themes/mint.css";
//import spiner from "../components/spiner.vue";
export default {
  name: "BlogDetail",
  components: {
    //spiner,
  },
  data() {
    return {
      section: [],
      documents: [],
    };
  },

  mounted() {
    //this.profile();
    this.getSection();
    this.getDocument();
  },
  methods: {
    async getSection() {
      try {
        const response = await axios.get(
          `/api/sections/${this.$route.params.id}`
        );
        if (response.data) {
          this.section = response.data.data;
          console.log(this.section);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getDocument() {
      try {
        const response = await axios.get(
          `/api/documents?section_id=${this.$route.params.id}`
        );
        if (response.data) {
          this.documents = response.data.data;
          console.log(this.sections);

          // Filtrer les rôles
        }
      } catch (error) {
        console.log(error.response ? error.response.data : error.message);
      }
    },

     formatDateTime(dateString, lang = TokenService.getLang()) {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };

  const date = new Date(dateString);

  // Format based on language ('en' for English, 'fr' for French)
  const locale = lang === 'fr' ? 'fr-FR' : 'en-US';

  return new Intl.DateTimeFormat(locale, options).format(date);
},

    
  },
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
