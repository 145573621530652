<template>
  <div class="lg:mt-32 mt-12 min-h-screen">
    <div class="lg:flex lg:ml-32 lg:mr-32 mx-6 lg:mx-0">
      <router-link
        v-if="this.user === '' || this.user === undefined"
        to="/auth/login"
      >
        <div
          class="flex bg-blue-500 rounded-full py-2 px-2 ml-9 lg:ml-0 text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>

          <p class="ml-2 mt-1 font-sans"> {{ $t('ask_your_question') }}</p>
        </div>
      </router-link>
      <router-link
        v-else-if="this.passer !== '' && this.user !== undefined"
        to="/question"
      >
        <div
          class="flex bg-blue-500 rounded-full py-2 px-2 ml-9 lg:ml-0 text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>

          <p class="ml-2 mt-1 font-sans">{{ $t('ask_your_question') }}</p>
        </div>
      </router-link>
      <router-link v-else to="/conditionForum">
        <div
          class="flex bg-blue-500 rounded-full py-2 px-2 ml-9 lg:ml-0 text-white"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>

          <p class="ml-2 mt-1 font-sans">{{ $t('ask_your_question') }}</p>
        </div>
      </router-link>
      <div class="lg:ml-auto ">
        <div
          class="rounded-full ml-9 lg:ml-0 text-gray-900 p-1 py-2 border border-gray-300 bg-white overflow-hidden ring-red-300 focus:ring-4  mt-4 lg:mt-0 lg:w-96 flex items-center py-1"
        >
          <input
            type="text"
            v-model="filter"
            class="rounded-full px-4 focus:outline-none w-full bg-white text-gray-900"
             :placeholder="$t('search')" 
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="w-6 h-6 mr-4"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
            />
          </svg>
        </div>
      </div>
    </div>
    <!-- <div
      class="flex ml-32 mr-32 mt-9 bg-purple-900 rounded-lg text-white px-4 py-2 hidden md:block"
    >
      <p class="font-sans">Résultats 1 - 50 sur un total d'environ 1090</p>
      <p class="ml-auto font-sans">Résolues | Fréquentes|</p>
    </div>-->
    <div
      v-for="(question, index) in paginatedCards"
      :key="index"
      class="lg:flex lg:ml-32 lg:mr-32 mt-9 mx-6 lg:mx-0"
    >
      <div class="w-3/4">
        <router-link
          :to="'/forumplus/' + question.id"
          class="hover:text-blue-500"
        >
          <h1 class="lg:text-3xl text-lg font-mono">
            {{ question.name }}
          </h1></router-link
        >
        <p class="text-left">
          Par {{ question.user.name }},
          {{
            new Date(question.updated_at).toLocaleDateString("fr-FR", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          }}
        </p>
      </div>
      <div class="ml-auto lg:w-1/4 flex ">
        <div class="mr-9">
          <p class="text-left">{{ question.nbr_repons }} réponses</p>
        </div>
        <div class="flex ml-9 hidden md:block">
          <div v-if="question.user.profile_photo_path === null" class="justify-start ">
            <img
              class="object-cover lg:w-20 lg:h-20 w-16 h-16 border-2 border-green-500 rounded-full"
              src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
            />
          </div>
          <div v-else class="justify-start ">
            <img
              class="object-cover lg:w-20 lg:h-20 w-16 h-16 border-2 border-green-500 rounded-full"
              :src="question.user.profile_photo_path"
            />
          </div>
          <div class="mt-1 ml-2">
            <p class="text-left">{{ question.user.name }}</p>
            <p class="text-left">
              {{
                new Date(question.user.updated_at).toLocaleDateString("fr-FR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                })
              }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex justify-center mt-9 mb-4" v-if="totalPages > 1">
      <nav aria-label="Page navigation example">
        <ul class="flex items-center -space-x-px h-12 text-sm">
          <li v-if="currentPage > 1">
            <a
              @click.prevent="goToPage(currentPage - 1)"
              class="flex items-center justify-center px-3 h-12 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Previous</span>
              <svg
                class="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </a>
          </li>
          <li v-for="page in pages" :key="page">
            <a
              @click.prevent="goToPage(page)"
              :class="{
                'z-10 flex items-center justify-center px-3 h-12 leading-tight': true,
                'text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white':
                  currentPage === page,
                'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white':
                  currentPage !== page,
              }"
            >
              {{ page }}
            </a>
          </li>
          <li v-if="currentPage < totalPages">
            <a
              @click.prevent="goToPage(currentPage + 1)"
              class="flex items-center justify-center px-3 h-12 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span class="sr-only">Next</span>
              <svg
                class="w-2.5 h-2.5 rtl:rotate-180"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      communitys: [],
      user: "",
      accepted: [],
      passer: "",
      filter: "",
      currentPage: 1,
      itemsPerPage: 5,
    };
  },
  mounted() {
    this.getCommunitys();
    this.profile();
  },
  computed: {
    sortedcommunitys() {
      // Triez les vidéos par date de création dans l'ordre décroissant
      return this.communitys
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
    totalPages() {
      return Math.ceil(this.sortedcommunitys.length / this.itemsPerPage);
    },
    filteredCommunity() {
      const searchTerm = this.filter.toLowerCase();
      const filtered_data = this.sortedcommunitys.filter((restaurants) => {
        const name = restaurants.name.toLowerCase();
        //const adresse = restaurants.adresse.toLowerCase();
        return name.includes(searchTerm);
      });
      return filtered_data;
    },
    paginatedCards() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;

      // Vérifier si la page actuelle est valide
      if (startIndex >= this.filteredCommunity.length || endIndex <= 0) {
        return []; // Retourner un tableau vide si la page est invalide
      }

      return this.filteredCommunity.slice(startIndex, endIndex);
    },
    // Vérifie si le bouton "Charger plus" doit être affiché
    showLoadMoreButton() {
      return (
        this.currentPage * this.itemsPerPage < this.filteredCommunity.length
      );
    },
    // pages() {

    //   return Array.from({ length: this.totalPages }, (_, index) => index + 1);
    // },
    pages() {
      const totalCards = this.filteredCommunity.length;
      const totalPages = Math.ceil(totalCards / this.itemsPerPage);
      return Array.from({ length: totalPages }, (_, index) => index + 1);
    },
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.user = response.data.id;
          this.getAccepted();
          console.log(this.user);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getAccepted() {
      try {
        const response = await axios.get(
          `/api/condictions?user_id=${this.user}`
        );
        if (response.data) {
          console.log(response.data.data);
          this.accepted = response.data.data;
          this.passer = this.accepted[0].user.id;

          console.log(this.passer);
          console.log(this.accepted);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getCommunitys() {
      try {
        const response = await axios.get("/api/communitys");
        if (response.data) {
          this.communitys = response.data.data;
          console.log(this.communitys);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    nextSlide() {
      if (this.currentIndex + 3 < this.slides.length) {
        this.currentIndex += 1;
        this.updateSlider();
      }
    },
    prevSlide() {
      if (this.currentIndex > 0) {
        this.currentIndex -= 1;
        this.updateSlider();
      }
    },
    updateSlider() {
      const translateValue = -this.currentIndex * (100 / 3) + "%";
      this.$refs.slider.style.transform = "translateX(" + translateValue + ")";
    },
    goToPage(page) {
      this.currentPage = page;
      console.log(window.innerHeight);
      window.scrollTo(0, 0); // Défilement jusqu'au quart de la page
    },

    loadMore() {
      // Ajoutez ici la logique pour charger plus d'éléments depuis votre source de données (définie localement)
      // Assurez-vous d'ajuster cette logique en fonction de votre situation réelle
      try {
        const newCards = this.getMoreCards(); // Remplacez par votre logique pour récupérer plus de cartes localement
        this.cards = this.cards.concat(newCards);
        this.currentPage++;
      } catch (error) {
        console.error(
          "Erreur lors du chargement des données supplémentaires",
          error
        );
      }
    },
  },
};
</script>

<style scoped></style>
