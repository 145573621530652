<script>
import axios from "axios";
import TokenService from "../services/storage.service";
import ApiService from "../services/api.service";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
import AlertComponent from "../components/AlertComponent.vue";
export default {
  name: "RegisterFrom",
  components: {
    spiner,
    AlertComponent,
  },
  data() {
    return {
      form: {
        email: "",
        password: "",
        role_id: "",
      },
      alert: {
        type: "",
        message: "",
      },
      processing: false,
      showAlert: false,
      isLoading: false,
      passwordErrorMessage: "",
      roles: [],
      filtremail:[],
        showPassword: false,
      showDropdown: false,
       selectedLang: "FR", 
    };
  },
  computed: {
    
    filteredRoles() {
      // Filtre les formations en fonction du roleID
      return this.roles.filter(
        (role) => role.name !== "Super Admin" && role.status === "En cours"
      );
    },
  },
  created() {
     this.selectedLang = TokenService.getLang();
    this.role();
    //this.Filtremail();
  },
  methods: {
     toggleLanguageDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    setLanguage(lang) {
      this.selectedLang = lang;
      this.showDropdown = false;
      this.translatePage();
    },
     async translatePage() {
      TokenService.saveLang(this.selectedLang);
      window.location.reload();
      this.newTranslate();
    },
    async newTranslate() {
      this.$i18n.locale = TokenService.getLang();
    },
    async role() {
      try {
        const response = await axios.get("/api/roles");
        if (response.data) {
          this.roles = response.data.data;

          // Filtrer les rôles
        }
      } catch (error) {
        console.log(error.response ? error.response.data : error.message);
      }
    },
   async Filtremail(id) {
      try {
        const response = await axios.get(`/api/filtreusers?role_id=${id}`);
        if (response.data) {
          this.filtremail = response.data.data;
          console.log(this.filtremail);

          // Filtrer les rôles
        }
      } catch (error) {
        console.log(error.response ? error.response.data : error.message);
      }
    },
async register() {
  try {
    this.isLoading = true;

    // Si roleID est différent de '21db4696-4e59-40fa-b009-4f8342428767', on vérifie l'adresse e-mail
    if (this.form.role_id !== '21db4696-4e59-40fa-b009-4f8342428767') {
      const emailExists = this.filtremail.some(
        (item) =>
          item.adressemail.toLowerCase() === this.form.email.toLowerCase()
      );

      if (!emailExists) {
        this.isLoading = false;

        // Vérification de la langue et affichage du message approprié
        const lang = TokenService.getLang();
        const emailNotExistsMessage = lang === 'fr' 
          ? "L'adresse e-mail n'existe pas dans la liste des utilisateurs autorisés."
          : "The email address is not on the list of authorized users.";

        new Noty({
          type: "error",
          layout: "topRight",
          text: emailNotExistsMessage,
          timeout: 5000,
        }).show();

        return;
      }
    }

    // Lancer la requête d'enregistrement
    const response = await axios.post("/api/auth/register", this.form);

    if (response.data.access_token) {
      TokenService.saveToken(response.data.access_token);
      ApiService.setHeader();
      this.isLoading = false;

      // Vérification de la langue et affichage du message approprié
      const lang = TokenService.getLang();
      const successMessage = lang === 'fr' 
        ? "Merci, votre compte a été créé avec succès."
        : "Thank you, your account has been successfully created.";

      new Noty({
        type: "success",
        layout: "topRight",
        text: successMessage,
        timeout: 5000,
      }).show();

      this.form = {};
      this.$router.push("/");
    }
  } catch (error) {
    this.isLoading = false;

    // Vérification de la langue et gestion des erreurs HTTP
    const lang = TokenService.getLang();

    if (error.response && error.response.status === 422) {
      this.showAlert = true;

      const invalidCredentialsMessage = lang === 'fr' 
        ? "Adresse e-mail ou mot de passe incorrect."
        : "Incorrect email or password.";

      this.alert.message = invalidCredentialsMessage;

    } else {
      this.showAlert = true;

      const generalErrorMessage = lang === 'fr' 
        ? "Quelque chose s'est mal passé. Merci d'essayer plus tard."
        : "Something went wrong. Please try again later.";

      this.alert.message = generalErrorMessage;
    }

    setTimeout(() => {
      this.showAlert = false;
    }, 5000);
  }
}


,
 togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    preventPaste(event) {
      event.preventDefault();
      const clipboardData = event.clipboardData || window.clipboardData;
      if (clipboardData) {
        const text = clipboardData.getData("text/plain");
        const passwordInput = document.getElementById("passwordInput");
        const startPosition = passwordInput.selectionStart;
        const endPosition = passwordInput.selectionEnd;
        const currentValue = this.form.password || "";
        const newValue =
          currentValue.substring(0, startPosition) +
          text +
          currentValue.substring(endPosition);
        this.form.password = newValue;
        this.validatePassword(); // Valider le mot de passe après le collage
      }
    },
    validatePassword() {
      // eslint-disable-next-line no-useless-escape
      const passwordPattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;

     if (!passwordPattern.test(this.form.password)) {
  const lang = TokenService.getLang();
  
  this.passwordErrorMessage = lang === 'fr' 
    ? "Le mot de passe doit contenir au moins 8 caractères, dont au moins une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial."
    : "The password must be at least 8 characters long, including at least one uppercase letter, one lowercase letter, one number, and one special character.";
} else {
  this.passwordErrorMessage = "";
}

    },
  },
};
</script>

<template>
  <div
    class="p-6 space-y-4 md:space-y-6 sm:p-8 lg:w-2/3 mx-auto my-auto mt-4 lg:mt-0 box-shadow-all-sides"
  >
    <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>
    <h1 class="font-bold text-xl">{{ $t('create_your_free_account') }}</h1>
    <form
      method="POST"
      action="#"
      class="space-y-4 md:space-y-6"
      @submit.prevent="!passwordErrorMessage && register()"
    >
    <div class="flex">
      <h3 class="font-sans text-lg">{{ $t('what_type_of_account') }}</h3>
     <div class="custom-language-selector ml-auto">
            <div @click="toggleLanguageDropdown" class="selected-language">
              <img
                :src="
                  selectedLang === 'fr'
                    ? require('../assets/27099.jpg')
                    : require('../assets/18166.jpg')
                "
                alt="selected language"
              />
            </div>
            <ul v-if="showDropdown" class="language-dropdown">
              <li @click="setLanguage('en')">
                <img src="../assets/18166.jpg" alt="English" />
              </li>
              <li @click="setLanguage('fr')">
                <img src="../assets/27099.jpg" alt="Français" />
              </li>
            </ul>
          </div>
          </div>
      <select
        name="category"
        id="category"
        v-model="form.role_id"
            @change="Filtremail($event.target.value)"
        class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
      >
        <option
          v-for="(role, index) in this.filteredRoles"
          :key="index"
          :value="role.id"
        >
          {{ role.name }}
        </option>
        <!-- Ajoutez plus d'options au besoin -->
      </select>
      <div class="">
        <label class="block font-bold text-sm text-gray-700 text-left"
          >{{ $t('name_and_surname') }}</label
        >
        <div class="mt-1">
          <input
            class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
            type="text"
            autocomplete="name"
            v-model="form.name"
            required
          />
        </div>
      </div>

      <div class="">
        <label class="block font-bold text-sm text-gray-700 text-left"
          >Email</label
        >
        <div class="mt-1">
          <input
            class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
            type="email"
            autocomplete="current-email"
            v-model="form.email"
            required
          />
        </div>
      </div>
      <div class="">
        <label class="block font-bold text-sm text-gray-700 text-left"
          >{{ $t('phone_optional') }}</label
        >
        <div class="mt-1">
          <input
            class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
            type="tel"
            autocomplete="current-tel"
          />
        </div>
      </div>
      <div class="space-y-1 mt-5">
        <label class="block font-bold text-sm text-gray-700 text-left"
          >Password</label
        >

        <div class="mt-1 relative">
  <input
    :type="showPassword ? 'text' : 'password'"
    class="block w-full p-2 pr-10 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
    v-model="form.password"
    autocomplete="current-password"
    @input="validatePassword"
    @paste="preventPaste"
    required
  />
  <button
    type="button"
    @click="togglePasswordVisibility"
    class="absolute inset-y-0 right-0 pr-3 flex items-center"
  >
    <svg
      v-if="showPassword"
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5 text-gray-500"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M10 3a7 7 0 015.464 2.536A7 7 0 0118 10a7 7 0 01-2.536 5.464A7 7 0 0110 18a7 7 0 01-5.464-2.536A7 7 0 012 10a7 7 0 012.536-5.464A7 7 0 0110 3zm0 2a5 5 0 00-5 5 5 5 0 005 5 5 5 0 005-5 5 5 0 00-5-5zm0 2a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"
      />
    </svg>
    <svg
      v-else
      xmlns="http://www.w3.org/2000/svg"
      class="h-5 w-5 text-gray-500"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
      />
    </svg>
  </button>
</div>

        <div class="text-red-500" v-if="passwordErrorMessage">
          {{ passwordErrorMessage }}
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="flex sm:items-start">
          <div class="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              class="rounded border-gray-300 mt-1 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
            />
          </div>
          <div class="lg:ml-1 ml-4 text-sm mt-4 lg:mt-0">
            <label
              for="remember"
              class="text-sm font-medium text-primary-normal dark:text-gray-300"
              >{{ $t('terms_acceptance') }}
            </label>
          </div>
        </div>

        <router-link
          to="/"
          class="text-sm font-medium text-blue-700 hover:underline dark:text-primary-500"
        >
         {{ $t('return_to_site') }}
        </router-link>
      </div>
      <button
        type="submit"
        class="bg-blue-500 text-white px-8 py-2 focus:outline-none poppins rounded-full mt-24 transform transition duration-300 hover:scale-105"
      >
        <span v-if="isLoading">
          <spiner />
        </span>
        <spam v-else class="ml-2 flex">
          <span> {{ $t('sign_in') }}</span>
        </spam>
      </button>
    </form>
    <p class="text-sm text-gray-500">
     {{ $t('already_have_account') }}
      <router-link
        to="/auth/login"
        class="font-sans text-blue-700 hover:underline dark:text-primary-500"
      >
        {{ $t('login') }}</router-link
      >
      <router-link
        to="/condition"
        class="font-sans text-blue-700 hover:underline dark:text-primary-500 ml-4"
      >
       {{ $t('terms_and_conditions') }}</router-link
      >
    </p>
  </div>
</template>
<style scoped>
.box-shadow-all-sides {
  box-shadow: 1px 1px 8px rgba(0.2, 0.2, 0.2, 0.2); /* Ajustez les valeurs en conséquence */
}
#passwordInput {
  /* Désactivez la fonction de collage en cachant le curseur lors du survol */
  pointer-events: none;
}
.language-selector {
  display: flex;
  align-items: center;
}

.language-selector select {
  margin-left: 10px;
  padding: 5px;
  font-size: 16px;
  background-color: #f0f0f0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.language-selector option {
  background-color: white;
  font-size: 16px;
  padding: 10px;
}
.custom-language-selector {
  position: relative;
  display: inline-block;
}

.selected-language img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.language-dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  padding: 0;
  list-style: none;
  width: 30px;
}

.language-dropdown li {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.language-dropdown li img {
  width: 80px;
  height: 20px;
  margin-right: 8px;
}

.language-dropdown li:hover {
  background-color: #f0f0f0;
}
</style>
