<template>
  <div class="lg:mx-32 mx-6 p-5 bg-white rounded-lg shadow mt-9">
    <div v-show="showAlert">
      <AlertComponent :content="alert.message" type-alert="error" />
    </div>
    <div class="flex items-center justify-between">
      <div class="w-2/3">
        <h2 class="section-heading">Questions and Answer</h2>
      </div>

      <div class="relative w-1/3 flex justify-end items-center space-x-1">
        <div
          class="prev-item flex items-center justify-center w-7 h-7 rounded bg-gray-100 hover:bg-gray-200 cursor-pointer"
        >
          <svg
            class="w-auto h-3 fill-current text-primary-black"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
          >
            <path
              d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"
            ></path>
          </svg>
        </div>
        <div
          class="next-item flex items-center justify-center w-7 h-7 rounded bg-gray-100 hover:bg-gray-200 cursor-pointer"
        >
          <svg
            class="w-auto h-3 fill-current text-primary-black"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 256 512"
          >
            <path
              d="M24.707 38.101L4.908 57.899c-4.686 4.686-4.686 12.284 0 16.971L185.607 256 4.908 437.13c-4.686 4.686-4.686 12.284 0 16.971L24.707 473.9c4.686 4.686 12.284 4.686 16.971 0l209.414-209.414c4.686-4.686 4.686-12.284 0-16.971L41.678 38.101c-4.687-4.687-12.285-4.687-16.971 0z"
            ></path>
          </svg>
        </div>
      </div>
    </div>

    <div class="mt-8 space-y-8">
      <h1 class="text-xl font-mono">{{ comminuty.name }}</h1>
      <div>
        <div class="relative flex items-center justify-center">
          <img class="mt-6 lg:mt-0" :src="comminuty.image_url" alt="" />
        </div>
        <div class="flex items-start mt-4">
          <div>
            <span
              class="inline-flex justify-center items-center w-6 h-6 rounded bg-green-500 text-white font-medium text-sm"
            >
              Q
            </span>
          </div>

          <p class="ml-4 md:ml-6">
            {{ comminuty.content }}
          </p>
        </div>

        <div
          class="flex items-start mt-3 "
          v-for="(commentaire, index) in posts"
          :key="index"
        >
          <div class="lg:w-2/3 flex">
            <span
              class="inline-flex justify-center items-center w-6 h-6 rounded bg-gray-200 text-gray-800 font-medium text-sm"
            >
              A
            </span>
            <p class="ml-4 md:ml-6 text-gray-800">
              {{ commentaire.content }}
            </p>
          </div>

          <div class="flex lg:w-1/3 md:ml-8 justify-end">
            <div
              v-if="commentaire.user.profile_photo_path === null"
              class="justify-start mb-4"
            >
              <img
                class="object-cover lg:w-12 lg:h-12 w-9 h-9 rounded-full"
                src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
              />
            </div>
            <div v-else class="">
              <img
                class="object-cover lg:w-12 lg:h-12 w-9 h-9 rounded-full"
                :src="commentaire.user.profile_photo_path"
              />
            </div>
            <div class="ml-2">
              <p class="text-sm text-gray-800 lg:ml-2">
                {{ commentaire.user.name }}
              </p>
              <p class="">
                {{
                  new Date(commentaire.updated_at).toLocaleDateString("fr-FR", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                  })
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mx-2 lg:mx-32 mt-4">
      <div class="mb-4">
        <div
          class="rounded-full p-1 box-border border border-orange-500 mt-6 bg-white overflow-hidden ring-red-300 focus:ring-4 lg:w-full w-64 mt-2 flex items-center py-1"
        >
          <input
            type="text"
            v-model="addform.content"
            class="rounded-full px-4 focus:outline-none w-full"
            placeholder="Votre reponse ......."
          />
          <button
            @click="Answer()"
            class="text-sm bg-blue-600 py-2 px-6 rounded-full text-white poppins ring-red-300 focus:ring-4 transition duration-300 hover:scale-105 transform"
          >
            <span v-if="isLoading">
              <spiner />
            </span>
            <span v-else> Envoyer</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
import AlertComponent from "../components/AlertComponent.vue";
export default {
  name: "Blog",
  components: {
    spiner,
    AlertComponent,
  },
  data() {
    return {
      addform: {
        user_id: "",
        community_id: "",
        content: "",
      },
      alert: {
        type: "",
        message: "",
      },
      showAlert: false,
      comminuty: [],
      posts: [],
      comminutyId: "",
      user: "",
      isLoading: false,
    };
  },
  mounted() {
    this.blogId = this.$route.params.id;
    console.log("ID du restaurant :", this.blogId);
    this.getCommutity();
    this.getAnwer();
    this.profile();
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.user = response.data.id;
          console.log(this.user);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getCommutity() {
      try {
        const response = await axios.get(
          `/api/communitys/${this.$route.params.id}`
        );
        if (response.data) {
          this.comminuty = response.data.data;
          console.log(this.comminuty);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async getAnwer() {
      try {
        const response = await axios.get(
          `/api/posts?community_id=${this.$route.params.id}`
        );
        if (response.data) {
          this.posts = response.data.data;
          //const allPosts = response.data.data;
          ///const communityId = this.$route.params.id;
          //this.posts = allPosts.filter(post => post.community.id === communityId);
          console.log(this.posts);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    async Answer() {
      try {
        if (this.user !== undefined && this.user !== "") {
          this.isLoading = true;
          this.addform.user_id = this.user;
          this.addform.community_id = this.$route.params.id;
          const response = await axios.post("/api/posts", this.addform);
          if (response.status === 201) {
            this.getAnwer();
            this.addform = {};
            this.isLoading = false;
            new Noty({
              type: "success",
              layout: "topRight",
              text: "Votre chapitre a été enregistré avec succès",
              timeout: 5000,
            }).show();
          }
        } else {
          this.showAlert = true;
          this.alert.message =
            "Veuillez vous connecter avant de repondre ou poser une question.";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose s'est mal passé. Merci d'essayer plus tard !";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
  },
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
