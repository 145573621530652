<template>
  <div class="">
    <div
      class="relative w-full mt-9 lg:mt-0 hidden md:block"
      style="height: 1900px; background-color: #015790"
    >
      <div
        class="absolute lg:top-72 md:top-44 text-white p-4 -top-1 lg:flex md:flex lg:mx-24 md:mx-4 lg:space-x-9 md:space-x-4"
      >
        <div
          class="lg:text-4xl text-lg text-left lg:w-1/2 bg-white text-black rounded-2xl"
        >
          <img
            class="hidden md:block lg:block -mt-44"
            src="../assets/logoforme.png"
            alt=""
          />
          <h1 class="lg:text-4xl text-blue-500 text-xl text-center font-mono">
            {{ $t("why") }}?
          </h1>
          <p class="mt-4 text-lg mx-12 mb-6 font-sans">
            {{ $t("cybersecurity_deficit") }}
          </p>
        </div>

        <div
          class="lg:text-4xl text-lg text-left lg:w-1/2 bg-blue-500 text-white rounded-2xl"
        >
          <h1 class="lg:text-4xl text-xl text-center font-mono mt-16">
            Description
          </h1>
          <p class="mt-24 text-lg mx-12 mb-6 font-sans">
            {{ $t("cse_trainings") }}
          </p>
        </div>
      </div>

      <div
        v-if="this.selectedLang === 'fr'"
        class="absolute -bottom-32 lg:-bottom-72 mx-6 text-black p-4 lg:mx-64 font-sans text-sm lg:text-4xl text-center"
      >
        <img class="w-full" src="../assets/Patrickquisuije.png" alt="" />
      </div>
      <div
        v-else
        class="absolute -bottom-32 lg:-bottom-72 mx-6 text-black p-4 lg:mx-64 font-sans text-sm lg:text-4xl text-center"
      >
        <img class="w-full" src="../assets/Patrickquisuijeen.png" alt="" />
      </div>
    </div>

    <div
      class="relative w-full mt-9 lg:mt-0 block md:hidden"
      style="min-height: 3300px; background-color: #015790"
    >
      <div
        class="text-white p-4 lg:flex md:flex lg:mx-24 md:mx-4 lg:space-x-9 md:space-x-4"
      >
        <div
          class="lg:text-4xl text-lg text-left lg:w-1/2 bg-white text-black rounded-2xl"
        >
          <img
            class="hidden md:block lg:block -mt-44"
            src="../assets/logoforme.png"
            alt=""
          />
          <h1 class="lg:text-4xl text-blue-500 text-xl text-center font-mono">
            {{ $t("why") }}?
          </h1>
          <p class="mt-4 text-lg mx-12 mb-6 font-sans">
            {{ $t("cybersecurity_deficit") }}
          </p>
        </div>

        <div
          class="lg:text-4xl text-lg text-left lg:w-1/2 bg-blue-500 text-white rounded-2xl"
        >
          <h1 class="lg:text-4xl text-xl text-center font-mono mt-16">
            Description
          </h1>
          <p class="mt-24 text-lg mx-12 mb-6 font-sans">
            {{ $t("cse_trainings") }}
          </p>
        </div>
      </div>

      <div
        v-if="this.selectedLang === 'fr'"
        class="mx-6 text-black font-sans text-sm text-center"
      >
        <img class="w-full" src="../assets/Patrickquisuije.png" alt="" />
      </div>
      <div v-else class="mx-6 text-black font-sans text-sm text-center">
        <img class="w-full" src="../assets/Patrickquisuijeen.png" alt="" />
      </div>
    </div>

    <div class="bg-purple-800">
      <div class="lg:mx-24 lg:flex mx-4">
        <div class="h-24 block lg:hidden"></div>
        <div class="lg:w-1/2 lg:mt-44">
          <div class="justify-start">
            <h1
              class="lg:text-2xl text-white text-xl text-center font-mono border border-white w-64 h-12 rounded-2xl"
            >
              {{ $t("highlights") }}
            </h1>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">{{ $t("data_center") }}</p>
                <p class="ml-4">{{ $t("data_center_desc") }}</p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">{{ $t("cloud") }}</p>
                <p class="ml-4">{{ $t("cloud_desc") }}</p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">{{ $t("cybersecurity_blue_team") }}</p>
                <p class="ml-4">{{ $t("cybersecurity_blue_team_desc") }}</p>
              </div>
            </div>
          </div>
          <div class="justify-start">
            <h1
              class="lg:text-2xl text-white text-xl mt-9 text-center font-mono border border-white w-72 h-12 rounded-2xl"
            >
              {{ $t("certifications") }}
            </h1>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-14 h-14 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">
                  {{ $t("certifications_desc") }}
                </p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-32 h-32 text-white mt-4 ml-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">
                  {{ $t("qualifications") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="lg:w-1/2 lg:mt-44">
          <div class="justify-start">
            <h1
              class="lg:text-2xl text-white text-xl text-center font-mono border border-white w-64 h-12 rounded-2xl"
            >
              {{ $t("passions") }}
            </h1>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-9 h-9 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">{{ $t("music") }}</p>
                <p class="ml-4">{{ $t("music_desc") }}</p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-7 h-7 text-white mt-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">{{ $t("martial_arts") }}</p>
                <p class="ml-4">{{ $t("martial_arts_desc") }}</p>
              </div>
            </div>
            <div class="flex text-white">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-8 h-8 text-white mt-4 ml-1"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                />
              </svg>
              <div>
                <p class="mt-4 ml-4">{{ $t("cybersecurity_red_team") }}</p>
                <p class="ml-4">{{ $t("cybersecurity_red_team_desc") }}</p>
              </div>
            </div>
            <img v-if="this.selectedLang === 'fr'" class="" src="../assets/0000000.png" alt="" />
            <img v-else class="" src="../assets/0000000en.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Composant de modal -->
  <ModalComponent :isOpen="isModalOpen" @close="closeModal" />
</template>

<script>
import ModalComponent from "../components/ModalComponent .vue";
import TokenService from "../services/storage.service";

export default {
  name: "App",
  components: {
    ModalComponent,
  },
  data() {
    return {
      isModalOpen: false,
      selectedLang: "FR",
    };
  },
  mounted() {
    this.startSlider();
  },
  created() {
    this.selectedLang = TokenService.getLang();
    //this.profile();
  },
  methods: {
    startSlider() {
      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, 5000); // Défilement toutes les 5 secondes
    },
    openModal() {
      this.isModalOpen = true; // Ouvrir le modal
    },
    closeModal() {
      this.isModalOpen = false; // Fermer le modal
    },
  },
};
</script>
<style scoped>
.custom-background2 {
  background-image: url("~@/assets/degrade.jpg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slider-item {
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
</style>
