<template>
  <nav class="bg-slate-50 lg:p-4">
    <div
      class="container mx-auto flex justify-between h-12 relative items-center"
    >
      <!-- Logo -->
      <router-link to="/">
        <img
          src="../assets/CSE-TRAININGS.png"
          alt="Logo"
          class="h-16 w-24 mt-9 lg:mt-0"
        />
      </router-link>

      <!-- Burger menu for mobile -->
      <button @click="isOpen = !isOpen" class="block lg:hidden">
        <svg
          class="w-6 h-6 mr-6 text-slate-950 hover:text-gray-300"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path d="M4 6h16M4 12h16m-7 6h7"></path>
        </svg>
      </button>
      <div
        :class="isOpen ? 'flex' : 'hidden'"
        class="w-56 absolute z-20 bg-purple-900 top-20 right-0 md:left-auto md:right-auto"
      >
        <ul class="list-none text-white mx-4">
          <li @click="isOpen = !isOpen">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
              to="/"
              >{{ $t("home") }}</router-link
            >
          </li>
          <li @click="isOpen = !isOpen" class="mt-2">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
              to="/video"
              >{{ $t("video") }}</router-link
            >
          </li>

          <li @click="isOpen = !isOpen" class="mt-2">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
              to="/communaute"
              >{{ $t("cse_community") }}</router-link
            >
          </li>
          <li @click="isOpen = !isOpen" class="mt-2">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
              to="/blog"
              >{{ $t("blog") }}</router-link
            >
          </li>
          <li @click="isOpen = !isOpen" class="mt-2">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
              to="/challenge"
              >{{ $t("challenge") }}</router-link
            >
          </li>
          <li @click="isOpen = !isOpen" class="mt-2">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
              to="/formations"
              >{{ $t("training") }}</router-link
            >
          </li>
          <li @click="isOpen = !isOpen" class="mt-2">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
              to="/motivation"
              >{{ $t("motivation") }}</router-link
            >
          </li>
          <li @click="isOpen = !isOpen" class="mt-2">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
              to="/propos"
              >{{ $t("about") }}
            </router-link>
          </li>
          <li @click="isOpen = !isOpen" class="mt-2">
            <router-link
              class="text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
              to="/contact"
              >{{ $t("contact") }}</router-link
            >
          </li>

          <!--<li class="mt-2">
            <div
              class="flex text-slate-950 hover:text-gray-300"
              active-class="text-blue-500"
            >
              <div class="relative inline-block text-left text-lg">
                <button
                  @click="toggleDropdown"
                  class="flex text-slate-950 hover:text-gray-300"
                  active-class="text-blue-500"
                >
                  Fr

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="w-5 h-5 ml-2 -mt-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
                <div
                  v-if="isDropdownOpen"
                  id="dropdown-menu"
                  class="origin-top-right absolute right-0 mt-2 w-12 rounded-md shadow-lg bg-blue-500 ring-1 ring-black ring-opacity-5"
                >
                  <div
                    class="py-2 p-2"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="dropdown-button"
                  >
                    <div
                      class=""
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="dropdown-button"
                    >
                      <router-link
                        class="text-white hover:text-slate-950"
                        active-class="text-slate-950"
                        to="/#"
                      >
                        En
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>-->
          <!-- Ajoutez les autres éléments de menu ici -->

          <!-- Sign in -->
          <li class="mt-2">
            <router-link
              v-if="this.user === undefined || this.user === ''"
              class="py-1 text-white poppins rounded-lg ring-red-300 focus:outline-none focus:ring-4 transform transition duration-700 hover:scale-105"
              to="/auth/login"
              >{{ $t("sign_in") }}</router-link
            >
            <div v-else>
              <div class="relative" @click="toggleDropdownsfil">
                <h2>{{ $t("profile") }}</h2>

                <!-- Dropdown menu -->
                <div
                  v-show="isDropdownOpenPrfile"
                  class="absolute right-0 z-10 mt-4 origin-top-right rounded-md bg-gray-800 w-48 py-1 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                  tabindex="-1"
                >
                  <!-- Active: "bg-gray-100", Not Active: "" -->
                  <a
                    href="#"
                    class="block px-4 py-2 text-sm text-white font-bold text-center"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-0"
                    >{{ this.name }}</a
                  >
                  <h1 class="text-xl font-bold border border-white mt-4"></h1>
                  <router-link
                    class="block px-4 py-2 flex text-sm text-white font-bold ml-9 mt-2"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-1"
                    to="/profils"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                    <span class="ml-2">{{ this.name }}</span></router-link
                  >
                  <button
                    @click="logout()"
                    class="block px-4 py-2 flex text-sm text-white font-bold ml-9 mt-2"
                    role="menuitem"
                    tabindex="-1"
                    id="user-menu-item-2"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                      />
                    </svg>

                    <span class="ml-2">{{ $t("logout") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </li>
           <li>
          <div class="custom-language-selector">
            <div @click="toggleLanguageDropdown" class="selected-language">
              <img
                :src="
                  selectedLang === 'fr'
                    ? require('../assets/27099.jpg')
                    : require('../assets/18166.jpg')
                "
                alt="selected language"
              />
            </div>
            <ul v-if="showDropdown" class="language-dropdown">
              <li @click="setLanguage('en')">
                <img src="../assets/18166.jpg" alt="English" />
              </li>
              <li @click="setLanguage('fr')">
                <img src="../assets/27099.jpg" alt="Français" />
              </li>
            </ul>
          </div>
        </li>
        </ul>
      </div>
      <!-- Menu -->
      <ul
        :class="{ hidden: !isMobileMenuOpen }"
        class="lg:flex lg:space-x-4 lg:text-lg"
      >
        <li @click="toggDropdown">
          <router-link
            class="text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
            to="/"
            >{{ $t("home") }}</router-link
          >
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
            to="/video"
            >{{ $t("video") }}</router-link
          >
        </li>

        <li @click="toggDropdown">
          <router-link
            class="text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
            to="/communaute"
            >{{ $t("cse_community") }}</router-link
          >
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
            to="/blog"
            >{{ $t("blog") }}</router-link
          >
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
            to="/challenge"
            >{{ $t("challenge") }}</router-link
          >
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
            to="/formations"
            >{{ $t("training") }}</router-link
          >
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
            to="/motivation"
            >{{ $t("motivation") }}</router-link
          >
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
            to="/propos"
            >{{ $t("about") }}
          </router-link>
        </li>
        <li @click="toggDropdown">
          <router-link
            class="text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
            to="/contact"
            >{{ $t("contact") }}</router-link
          >
        </li>

        <!-- <li>
          <div
            class="flex items-center justify-center text-slate-950 hover:text-gray-300"
            active-class="text-blue-500"
          >
            <div class="relative inline-block text-left text-lg">
              <button
                @click="toggleDropdown"
                class="flex text-slate-950 hover:text-gray-300"
                active-class="text-blue-500"
              >
                Fr

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-5 h-5 ml-2 -mr-1"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </button>
            </div>
          </div>
        </li>-->
        <!-- Ajoutez les autres éléments de menu ici -->

        <!-- Sign in -->
        <li class="hidden lg:block">
          <router-link
            v-if="this.user === undefined || this.user === ''"
            class="bg-blue-400 px-4 py-1 text-white poppins rounded-lg ring-red-300 focus:outline-none focus:ring-4 transform transition duration-700 hover:scale-105"
            to="/auth/login"
            >{{ $t("sign_in") }}</router-link
          >
          <div v-else>
            <div class="relative" @click="toggleDropdownsfil">
              <button
                type="button"
                class="relative flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
              >
                <span class="absolute -inset-1.5"></span>
                <span class="sr-only">Open user menu</span>
                <img
                  v-if="this.imageUrl === null"
                  class="rounded-full"
                  src="../assets/c972ce3d5e2f4ea3d8d0b83ef3423dd1.png"
                  alt=""
                />
                <img
                  v-else
                  class="w-12 h-12 rounded-full border-2 border-white cursor-pointer"
                  :src="this.imageUrl"
                  alt=""
                />
              </button>

              <!-- Dropdown menu -->
              <div
                v-show="isDropdownOpenPrfile"
                class="absolute right-0 z-10 mt-4 origin-top-right rounded-md bg-gray-800 w-48 py-1 shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
                tabindex="-1"
              >
                <!-- Active: "bg-gray-100", Not Active: "" -->
                <a
                  href="#"
                  class="block px-4 py-2 text-sm text-white font-bold text-center"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-0"
                  >{{ this.name }}</a
                >
                <h1 class="text-xl font-bold border border-white mt-4"></h1>
                <router-link
                  class="block px-4 py-2 flex text-sm text-white font-bold ml-9 mt-2"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-1"
                  to="/profils"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                    />
                  </svg>
                  <span class="ml-2">{{ $t("profile") }}</span></router-link
                >
                <button
                  @click="logout()"
                  class="block px-4 py-2 flex text-sm text-white font-bold ml-9 mt-2"
                  role="menuitem"
                  tabindex="-1"
                  id="user-menu-item-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                    />
                  </svg>

                  <span class="ml-2">{{ $t("logout") }}</span>
                </button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="custom-language-selector">
            <div @click="toggleLanguageDropdown" class="selected-language">
              <img
                :src="
                  selectedLang === 'fr'
                    ? require('../assets/27099.jpg')
                    : require('../assets/18166.jpg')
                "
                alt="selected language"
              />
            </div>
            <ul v-if="showDropdown" class="language-dropdown">
              <li @click="setLanguage('en')">
                <img src="../assets/18166.jpg" alt="English" />
              </li>
              <li @click="setLanguage('fr')">
                <img src="../assets/27099.jpg" alt="Français" />
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import axios from "axios";
import TokenService from "../services/storage.service";
//import ApiService from "../services/api.service";
export default {
  name: "Navbar",
  data() {
    return {
      isDropdownOpen: false,
      isDropdownOpens: false,
      isMobileMenuOpen: false,
      isDropdownOpenPrfile: false,
      isOpen: false,
      showDropdown: false,
      user: "",
      name: "",
      imageUrl: "",
      roleID: "",
      selectedLang: "fr", // Langue par défaut
    };
  },
  created() {
    this.selectedLang = TokenService.getLang();
    this.profile();
  },
  mounted() {
    //this.translatePage();
  },
  methods: {
    toggleDropdownsfil() {
      this.isDropdownOpenPrfile = !this.isDropdownOpenPrfile;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      this.isDropdownOpens = false;
    },
    toggDropdown() {
      this.isDropdownOpens = false;
      this.isDropdownOpen = false;
      this.isDropdownOpenPrfile = false;
    },
    toggleDropdowns() {
      this.isDropdownOpens = !this.isDropdownOpens;
      this.isDropdownOpen = false;
    },
    toggleDrop() {
      this.isDropdownOpens = !this.isDropdownOpens;
      this.isOpen = !this.isOpen;
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.name = response.data.name;
          this.user = response.data.id;
          this.roleID = response.data.role_id;
          this.imageUrl = response.data.profile_photo_path;
          console.log(this.user);
        }
      } catch (error) {
        this.user = error.data;
        console.log(this.user);
      }
    },
    async logout() {
      try {
        const response = await axios.get("/api/logout");
        if (response.status === 204) {
          TokenService.removeToken();
          this.user = "";
        }
      } catch (error) {
        console.log(error);
      }
    },
    getTextNodesIn(node) {
      let all = [];
      for (node = node.firstChild; node; node = node.nextSibling) {
        if (node.nodeType === 3 && node.nodeValue.trim()) {
          // Nœud de texte
          all.push(node);
        } else if (node.nodeType === 1) {
          // Élément
          all = all.concat(this.getTextNodesIn(node));
        }
      }
      return all;
    },

    async translateText(texts, targetLang = "EN") {
      const API_KEY = "fd8919e8-1ac3-4141-a255-03d6c8b8bca6:fx"; // Remplacez par votre clé API
      const url = "https://api-free.deepl.com/v2/translate";

      try {
        const response = await axios.post(url, null, {
          params: {
            auth_key: API_KEY,
            text: texts, // envoyer un tableau de textes
            target_lang: targetLang,
          },
        });
        console.log("Réponse de la traduction :", response.data.translations);
        return response.data.translations.map((t) => t.text); // Extraire les textes traduits
      } catch (error) {
        console.error("Erreur lors de la traduction :", error);
        return texts; // Retourner le texte original en cas d'erreur
      }
    },
    toggleLanguageDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    setLanguage(lang) {
      this.selectedLang = lang;
      this.showDropdown = false;
      this.translatePage();
    },
    async translatePage() {
      TokenService.saveLang(this.selectedLang);
      window.location.reload();
      this.newTranslate();
    },
    async newTranslate() {
      this.$i18n.locale = TokenService.getLang();
    },
  },
};
</script>
<style>
.language-selector {
  display: flex;
  align-items: center;
}

.language-selector select {
  margin-left: 10px;
  padding: 5px;
  font-size: 16px;
  background-color: #f0f0f0;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.language-selector option {
  background-color: white;
  font-size: 16px;
  padding: 10px;
}
.custom-language-selector {
  position: relative;
  display: inline-block;
}

.selected-language img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.language-dropdown {
  position: absolute;
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  padding: 0;
  list-style: none;
  width: 30px;
  z-index: 10; /* S'assure que la liste est au-dessus d'autres éléments */
}

.language-dropdown li {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
}

.language-dropdown li img {
  z-index: 11; /* Permet aux images d'être au-dessus d'autres éléments */
  pointer-events: auto; /* Permet de cliquer sur les images */
}

.language-dropdown li:hover img {
  transform: scale(1.1); /* Agrandit légèrement l'image au survol pour un effet visuel */
}


.language-dropdown li img {
  width: 80px;
  height: 20px;
  margin-right: 8px;
}

.language-dropdown li:hover {
  background-color: #f0f0f0;
}
</style>
