<template>
  <div class="min-h-screen">
    <div class="mt-16 relative text-center mx-auto">
      <p class="text-4xl text-blue-400 font-mono">{{ $t('motivation_and_proverbs') }}</p>
    </div>

    <div class="lg:flex lg:flex-wrap mx-12 mt-6">
      <div
        v-for="(motivation, index) in sortedMotivations"
        :key="index"
        class="mt-4 lg:w-1/3"
      >
        <img
          class="w-full lg:p-4"
          :src="motivation.image_url"
          :alt="motivation.alt"
        />
      </div>
    </div>

    <div class="h-9"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Motivation",
  data() {
    return {
      motivations: [],
    };
  },
  mounted() {
    this.getMotivationss();
  },
  computed: {
    sortedMotivations() {
      // Triez les vidéos par date de création dans l'ordre décroissant
      return this.motivations
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
  },
  methods: {
    async getMotivationss() {
      try {
        const response = await axios.get("/api/motivations");
        if (response.data) {
          this.motivations = response.data.data;
          console.log(this.motivations);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
  },
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
