<template>
  <div class="lg:mx-32 p-5 bg-white rounded-lg shadow mt-9">
    <div class="flex items-center justify-between">
      <div class="lg:w-2/3 ml-4 lg:ml-0">
        <h2 class="section-heading lg:text-3xl text-xl font-mono">your_question
         {{ $t('your_question') }}
        </h2>
      </div>
    </div>

    <form action="#" method="POST" @submit.prevent="submitForm()">
      <div>
        <div class="px-4 py-5 bg-white p-6">
          <div class="flex">
            <div class="lg:w-2/3 lg:mr-9 w-80">
              <BaseLabel :value=" $t('titles')" class="text-left font-bold" />
              <BaseInput v-model="addform.name" id="nom" class="mt-1" />

              <div class="col-span-8 sm:col-span-8">
                <BaseLabel :value=" $t('contents')" class="text-left mt-2 font-bold" />
                <div class="relative mt-1">
                  <textarea
                    v-model="addform.content"
                    class="block lg:w-full p-2 h-48 w-80 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border"
                  />
                </div>
              </div>
              <div class="col-span-8 sm:col-span-8">
                <BaseLabel
                  :value=" $t('image_or_capture_optional')" 
                  class="text-left mt-2 font-bold"
                />
                <input
                  id="image"
                  type="file"
                  @change="onFileChange"
                  class="block w-full p-2 border border-input-disable rounded-md focus:outline-none focus:ring-primary-normal focus:ring focus:ring-opacity-50 shadow-sm focus:border mt-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Button
        type="submit"
        class="bg-blue-500 text-white px-8 py-2 ml-4 lg:ml-0 focus:outline-none rounded-lg mt-2 transform transition duration-300"
      >
        <span v-if="isLoading">
          <spiner />
        </span>
        <span v-else> {{ $t('send') }}</span>
      </Button>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import BaseLabel from "../components/BaseLabel.vue";
//import spiner from "../components/spiner.vue";
import BaseInput from "../components/BaseInput.vue";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import spiner from "../components/spiner.vue";
export default {
  name: "Blog",
  components: {
    BaseLabel,
    BaseInput,
    spiner,
  },
  data() {
    return {
      addform: {
        name: "",
        user_id: "",
        //description: "",
        image_url: "",
        content: "",
      },
      alert: {
        type: "",
        message: "",
      },
      showAlert: false,
      processing: false,
      isLoading: false,
      user: "",
    };
  },
  created() {
    this.profile();
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.user = response.data.id;
          console.log(this.user);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    addQuestion() {
      this.isLoading = true;

      const formData = new FormData();
      formData.append("file", this.image);

      axios
        .post("api/medias", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          // Définir un délai d'attente de 1 minute (en millisecondes)
          timeout: 60000,
        })
        .then((response) => {
          if (response.status === 201) {
            this.addform.image_url = response.data.data.media_url;
            console.log(this.addform.image_url);
            this.chapitre();
          }
        })
        .catch((error) => {
          // Si une erreur survient ou si le délai d'attente est dépassé,
          // error.response sera undefined. Vous pouvez donc vérifier
          // si error.response existe avant de traiter la réponse.
          if (error.response) {
            console.error(error.response.data);
            console.error(error.response.status);
            console.error(error.response.headers);
          } else if (error.request) {
            // La requête a été faite mais aucune réponse n'a été reçue
            console.error(error.request);
          } else {
            // Une erreur est survenue lors de la configuration de la requête
            console.error("Error", error.message);
          }
        })
        .finally(() => {
          // Réinitialiser isLoading après l'exécution de la requête
          this.isLoading = false;
        });
    },
    async chapitre() {
      try {
        this.isLoading = true;
        this.addform.user_id = this.user;
        const response = await axios.post("/api/communitys", this.addform);
        if (response.status == 201) {
          this.addform = {};
          this.isLoading = false;
          new Noty({
            type: "success",
            layout: "topRight",
            text: "Votre chapitre à été enregistrer avec succés",
            timeout: 5000,
          }).show();
          this.$router.push("/communaute");
        } else {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      } catch (error) {
        if (error.response.status !== 500) {
          this.showAlert = true;
          this.alert.message =
            "Quelque chose c'est mal passé. Merci d'essayer plus tard!";
          setTimeout(() => {
            this.showAlert = false;
          }, 5000);
        }
      }
    },
    submitForm() {
      if (this.image) {
        this.addQuestion();
      } else {
        this.chapitre();
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.image = file;
    },
  },
};
</script>

<style scoped>
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
