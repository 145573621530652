<template>
  <div class="">
    <div class="relative mt-9 lg:mt-0 min-h-screen" style="background-color: #015790">
      <div class="h-9"></div>
      <div class="lg:flex lg:space-x-2 lg:space-y-0 mx-6">
        <div
          v-for="formation in sortedFormations"
          :key="formation.id"
          class="lg:w-1/3 p-2"
        >
          <div
            class="border border-white p-2 lg:flex space-x-2 lg:space-y-0 space-y-4"
          >
            <div
              v-if="
                formation.roleID === this.role_ID ||
                this.role_ID === '5cb0663d-c4ee-4a77-8f3d-f6505ea400df'
              "
              class="lg:w-1/2"
            >
              <router-link :to="'/formation/' + formation.id">
                <img
                  class="rounded-lg"
                  :src="formation.info_complete"
                  :alt="formation.name"
                />
              </router-link>
            </div>
            <div
              v-else-if="this.user === undefined || this.user === ''"
              class="lg:w-1/2"
            >
              <router-link to="/auth/login">
                <img
                  class="rounded-lg"
                  :src="formation.info_complete"
                  :alt="formation.name"
                />
              </router-link>
            </div>
            <div v-else class="lg:w-1/2">
              <div @click="goMessage" class="cursor-pointer">
                <img
                  class="rounded-lg"
                  :src="formation.info_complete"
                  :alt="formation.name"
                />
              </div>
            </div>

            <div
              class="lg:w-1/2"
              v-if="
                formation.roleID === this.role_ID ||
                this.role_ID === '5cb0663d-c4ee-4a77-8f3d-f6505ea400df'
              "
            >
              <router-link :to="'/formation/' + formation.id">
                <h1 class="text-white lg:text-xl text-center font-mono">
                  {{ formation.name }}
                </h1>
              </router-link>

             
               <div class="bg-white mt-4">
                <div class="h-1"></div>
                <div
                  style="
                    height: 4px;
                    width: 180px;
                    background-color: rgb(107 33 168);
                    margin: 10px auto 0;
                  "
                ></div>
                <div class="flex">
                  <div class="flex mx-2 items-center ">
                    <!-- <div
                      class="flex justify-center items-center w-6 h-6 bg-purple-800 text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                        />
                      </svg>
                    </div> -->
                    <div>
                      <h4 class="ml-1 font-bold text-black">{{ $t('start') }}</h4>
                      <p class="ml-1 text-xs text-black whitespace-nowrap">
                        {{ formatDate(formation.date) }}
                      </p>
                    </div>
                  </div>

                  <div class="flex mx-2 items-center">
                    <!-- <div
                      class="flex justify-center items-center w-6 h-6 bg-purple-800 text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                        />
                      </svg>
                    </div> -->
                    <div>
                      <h4 class="ml-1 font-bold text-black">{{ $t('end') }}</h4>
                      <p class="ml-1 text-xs text-black whitespace-nowrap">
                        {{ formatDate(formation.meet_url) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    height: 4px;
                    width: 180px;
                    background-color: rgb(107 33 168);
                    margin: 10px auto 0;
                  "
                ></div>
                <h4 class="ml-4 font-bold text-blue-500 ml-2">
                  {{ formation.status }}
                </h4>
              </div>
            </div>
             <div
              class="lg:w-1/2"
             v-else-if="this.user === undefined || this.user === ''"
            >
              <router-link to="/auth/login">
                <h1 class="text-white lg:text-xl text-center font-mono">
                  {{ formation.name }}
                </h1>
              </router-link>

             
               <div  class="bg-white mt-4">
                <div class="h-1"></div>
                <div
                  style="
                    height: 4px;
                    width: 180px;
                    background-color: rgb(107 33 168);
                    margin: 10px auto 0;
                  "
                ></div>
                <div class="flex">
                  <div class="flex mx-2 items-center ">
                    <!-- <div
                      class="flex justify-center items-center w-6 h-6 bg-purple-800 text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                        />
                      </svg>
                    </div> -->
                    <div>
                      <h4 class="ml-1 font-bold text-black">{{ $t('start') }}</h4>
                      <p class="ml-1 text-xs text-black whitespace-nowrap">
                        {{ formatDate(formation.date) }}
                      </p>
                    </div>
                  </div>

                  <div class="flex mx-2 items-center">
                    <!-- <div
                      class="flex justify-center items-center w-6 h-6 bg-purple-800 text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                        />
                      </svg>
                    </div> -->
                    <div>
                      <h4 class="ml-1 font-bold text-black">{{ $t('end') }}</h4>
                      <p class="ml-1 text-xs text-black whitespace-nowrap">
                        {{ formatDate(formation.meet_url) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    height: 4px;
                    width: 180px;
                    background-color: rgb(107 33 168);
                    margin: 10px auto 0;
                  "
                ></div>
                <h4 class="ml-4 font-bold text-blue-500 ml-2">
                  {{ formation.status }}
                </h4>
              </div>
            </div>
             <div
              class="lg:w-1/2"
             v-else
            >
             
                <h1 @click="goMessage" class="text-white lg:text-xl text-center font-mono cursor-pointer">
                  {{ formation.name }}
                </h1>
              

            
               <div class="bg-white mt-4">
                <div class="h-1"></div>
                <div
                  style="
                    height: 4px;
                    width: 180px;
                    background-color: rgb(107 33 168);
                    margin: 10px auto 0;
                  "
                ></div>
                <div class="flex">
                  <div class="flex mx-2 items-center ">
                    <!-- <div
                      class="flex justify-center items-center w-6 h-6 bg-purple-800 text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                        />
                      </svg>
                    </div> -->
                    <div>
                      <h4 class="ml-1 font-bold text-black">{{ $t('start') }}</h4>
                      <p class="ml-1 text-xs text-black whitespace-nowrap">
                        {{ formatDate(formation.date) }}
                      </p>
                    </div>
                  </div>

                  <div class="flex mx-2 items-center">
                    <!-- <div
                      class="flex justify-center items-center w-6 h-6 bg-purple-800 text-white rounded-full"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4 text-white"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6.75 2.994v2.25m10.5-2.25v2.25m-14.252 13.5V7.491a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v11.251m-18 0a2.25 2.25 0 0 0 2.25 2.25h13.5a2.25 2.25 0 0 0 2.25-2.25m-18 0v-7.5a2.25 2.25 0 0 1 2.25-2.25h13.5a2.25 2.25 0 0 1 2.25 2.25v7.5m-6.75-6h2.25m-9 2.25h4.5m.002-2.25h.005v.006H12v-.006Zm-.001 4.5h.006v.006h-.006v-.005Zm-2.25.001h.005v.006H9.75v-.006Zm-2.25 0h.005v.005h-.006v-.005Zm6.75-2.247h.005v.005h-.005v-.005Zm0 2.247h.006v.006h-.006v-.006Zm2.25-2.248h.006V15H16.5v-.005Z"
                        />
                      </svg>
                    </div> -->
                    <div>
                      <h4 class="ml-1 font-bold text-black">{{ $t('end') }}</h4>
                      <p class="ml-1 text-xs text-black whitespace-nowrap">
                        {{ formatDate(formation.meet_url) }}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  style="
                    height: 4px;
                    width: 180px;
                    background-color: rgb(107 33 168);
                    margin: 10px auto 0;
                  "
                ></div>
                <h4 class="ml-4 font-bold text-blue-500 ml-2">
                  {{ formation.status }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="h-9"></div>
    </div>
  </div>
  <!-- Composant de modal -->
  <ModalComponent :isOpen="isModalOpen" @close="closeModal" />
</template>

<script>
import ModalComponent from "../components/ModalComponent .vue";
import axios from "axios";
import Noty from "noty";
import "noty/lib/noty.css";
import "noty/lib/themes/mint.css";
import TokenService from "../services/storage.service";
export default {
  name: "App",
  components: {
    ModalComponent,
  },
  data() {
    return {
      isModalOpen: false,
      formations: [],
      role_ID: "",
      user: "",
    };
  },
  mounted() {
    this.getFormations();
    this.profile();
  },
  computed: {
    sortedFormations() {
      // Triez les vidéos par date de création dans l'ordre décroissant
      return this.formations
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
  },
  methods: {
    async profile() {
      try {
        const response = await axios.get("/api/profile");
        if (response.data) {
          this.name = response.data.name;
          this.user = response.data.id;
          this.role_ID = response.data.role_id;
          this.imageUrl = response.data.profile_photo_path;
        }
      } catch (error) {
        this.user = error.data;
      }
    },
    async getFormations() {
      try {
        axios.defaults.headers.common['lang'] = TokenService.getLang();
        const response = await axios.get("/api/formations");
        if (response.data) {
          this.formations = response.data.data;
        }
        this.Section(this.formationID);
      } catch (error) {
        console.log(error.data);
      }
    },

    formatDate(dateString) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      const date = new Date(dateString);
      return new Intl.DateTimeFormat("fr-FR", options).format(date);
    },
    goMessage() {
      new Noty({
        type: "error",
        layout: "topRight",
        text: "Désolé, vous n'êtes pas éligible pour cette formation.",
        timeout: 5000,
      }).show();
    },
  },
};
</script>
<style scoped>
.custom-background2 {
  background-image: url("~@/assets/degrade.jpg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slider-item {
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
</style>
