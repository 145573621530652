<template>
  <div class="">
    <div class="relative lg:w-full lg:mt-4">
      <img class="w-full" src="../assets/Perso.png" alt="" />
      <div class="absolute top-9 lg:top-64 lg:left-24 text-white p-4 md:top-32">
        <h1 class="lg:text-4xl text-sm font-mono text-left md:text-xl">
          {{ $t('start_to') }} <br />{{ $t('learn') }} <br />
          {{ $t('today') }}
        </h1>
        <div class="lg:text-2xl text-sm font-serif text-left mt-2">
          <span class="bg-blue-400 rounded-lg px-2"
            >{{ $t('change_your_future') }}</span
          >
        </div>
      </div>
    </div>
    <div class="relative w-full">
      <div class="lg:flex absolute -top-4 lg:top-0 left-0 text-blue-600 p-4">
        <div v-if="this.selectedLang ==='fr'" class="lg:w-1/3">
          <img class="lg:w-full lg:ml-6" src="../assets/gagnon.png" alt="" />
        </div>
         <div v-else class="lg:w-1/3">
          <img class="lg:w-full lg:ml-6" src="../assets/gagnonen.png" alt="" />
        </div>
        <div class="lg:w-2/3">
          <h1
            class="lg:text-4xl text-sm font-serif lg:text-blue-500 text-left mx-2 lg:mx-0 lg:ml-9 lg:mt-9 md:text-xl md:text-white md:text-center"
          >
           {{ $t('become_member_today') }}
          </h1>

          <div
            class="cincopa_responsive_iframe mt-2 lg:w-2/3 lg:ml-24 md:mt-44 lg:mt-4"
            style="padding: 56.25% 0 0 0; position: relative"
          >
            <div class="cincopa_responsive_wrapper">
              <iframe
                width="100%"
                height="100%"
                src="https://rtcdn.cincopa.com/iframe.aspx?fid=A4HAcLOLOO68!AEGDXHZM-KT6"
                frameborder="0"
                allowfullscreen
                scrolling="no"
                allow="autoplay *; fullscreen *"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="custom-background2 mt-72">
      <div class="relative w-full">
        <div
          class="lg:flex absolute top-48 lg:top-0 md:top-100 left-0 text-white p-4"
        >
          <div
            class="bg-purple-900 lg:w-1/3 mt-6 lg:ml-6 rounded-2xl md:mt-24 lg:mt-4"
          >
            <p class="ml-9 mr-9 mt-4 mb-4">
                 {{ $t('cse_trainings_description') }}
            </p>
          </div>
          <div class="w-2/3 hidden md:block"></div>
        </div>
      </div>
      <div class="h-9"></div>
      <div class="ml-auto mr-auto lg:mt-64 mt-96 text-white">
        <h1 class="lg:text-4xl text-xl text-center font-mono">{{ $t('our_videos') }}</h1>
      </div>
      <div class="flex mt-6 md:mt-80 flex-wrap lg:mt-0 justify-center">
        <div
          v-for="(video, index) in sortedVideos.slice(0, 8)"
          :key="index"
          class="lg:w-1/4 p-4 md:mx-auto lg:mx-0"
        >
          <div
            class="bg-purple-900 mt-4 transition transform duration-700 hover:shadow-xl hover:scale-105 rounded-lg relative"
          >
            <iframe
              class="rounded-lg w-full h-48 mt-2"
              :src="formattedVideoUrl(video.video_url)"
   
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>

            <div class="flex flex-col text-left mx-2">
              <h2 class="text-white text-lg font-bold">{{ video.name }}</h2>
            </div>
          </div>
        </div>
      </div>

      <!--<div class="bg-purple-900">
        <div class="h-2 bg-purple-900"></div>
        <div class="my-16 text-center">
          <h2 class="text-3xl font-mono text-white">TEMOIGNAGES</h2>
        </div>
        <div class="grid gap-2 lg:grid-cols-3 mx-9 mt-6 ">
          <div class="max-w-md p-4 border border-white rounded-3xl shadow-lg md:mx-auto">
            <div>
              <p class="mt-2 text-white text-left text-sm">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                dolores deserunt ea doloremque natus error, rerum quas odio
                quaerat nam ex commodi hic, suscipit in a veritatis pariatur
                minus consequuntur!
              </p>
            </div>
            <div class="flex mt-4">
              <div class="justify-start">
                <img
                  class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
                  src="../assets/young-3061652__340.jpg"
                />
              </div>

              <a
                href="#"
                class="text-xl font-medium text-white mt-4 text-left ml-6"
                >John Doe <br />
                <spam class="text-sm">Eleve Tle Scientifique</spam></a
              >
            </div>
          </div>
          <div class="max-w-md p-4 border border-white rounded-lg shadow-lg md:mx-auto">
            <div>
              <p class="mt-2 text-white text-left text-sm">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                dolores deserunt ea doloremque natus error, rerum quas odio
                quaerat nam ex commodi hic, suscipit in a veritatis pariatur
                minus consequuntur!
              </p>
            </div>
            <div class="flex mt-4">
              <div class="justify-start">
                <img
                  class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
                  src="../assets/purchase-3090818__340.jpg"
                />
              </div>

              <a
                href="#"
                class="text-xl font-medium text-white mt-4 text-left ml-6"
                >John Doe <br />
                <spam class="text-sm">Eleve Tle Scientifique</spam></a
              >
            </div>
          </div>
          <div class="max-w-md p-4 border border-white rounded-lg shadow-lg md:mx-auto">
            <div>
              <p class="mt-2 text-white text-sm text-left">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Quae
                dolores deserunt ea doloremque natus error, rerum quas odio
                quaerat nam ex commodi hic, suscipit in a veritatis pariatur
                minus consequuntur!
              </p>
            </div>
            <div class="flex mt-4">
              <div class="justify-start">
                <img
                  class="object-cover w-20 h-20 border-2 border-green-500 rounded-full"
                  src="../assets/young-3061652__340.jpg"
                />
              </div>

              <a
                href="#"
                class="text-xl font-medium text-white mt-4 text-left ml-6"
                >John Doe <br />
                <spam class="text-sm">Eleve Tle Scientifique</spam></a
              >
            </div>
          </div>
        </div>
      </div>-->
    </div>
  </div>
  <!-- Composant de modal -->
  <ModalComponent :isOpen="isModalOpen" @close="closeModal" />
</template>

<script>
import axios from "axios";
import ModalComponent from "../components/ModalComponent .vue";
import TokenService from "../services/storage.service";
//import { VideoPlayer } from "@videojs-player/vue";
//import "video.js/dist/video-js.css";

export default {
  name: "App",
  components: {
    ModalComponent,
    //VideoPlayer,
  },
  data() {
    return {
      isModalOpen: false,
      yourWidthVariable: "700%", // Ajoutez la valeur de la largeur souhaitée ici
      yourHeightVariable: "300%",
      videos: [],
      selectedLang: "FR",
    };
  },
  created() {
    this.selectedLang = TokenService.getLang();
    //this.profile();
  },
  mounted() {
    //this.startSlider();
    this.getVideos();
  },
  computed: {
    sortedVideos() {
      // Triez les vidéos par date de création dans l'ordre décroissant
      return this.videos
        .slice()
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    },
  },
  methods: {
    formattedVideoUrl(videoUrl) {
      // Convertit l'URL de YouTube en URL utilisable dans l'iframe
      const videoId = videoUrl.split('v=')[1];
      return `https://www.youtube.com/embed/${videoId}`;
    },
    async getVideos() {
      try {
        axios.defaults.headers.common['lang'] = TokenService.getLang();
        const response = await axios.get("/api/videos");
        if (response.data) {
          this.videos = response.data.data;
          console.log(this.videos);
        }
      } catch (error) {
        console.log(error.data);
      }
    },
    openModal() {
      this.isModalOpen = true; // Ouvrir le modal
    },
    closeModal() {
      this.isModalOpen = false; // Fermer le modal
    },
  },
};
</script>

<style scoped>
.custom-background2 {
  background-image: url("~@/assets/fond.jpg");
  /* Remplacez 'votre-image.jpg' par le nom de votre image */
  background-size: cover;
  /* Ajustez la taille de l'image */
  background-repeat: no-repeat;
  background-position: center center;
  /* Centrez l'image */
  /* Ajoutez d'autres styles Tailwind CSS ou CSS personnalisés au besoin */
}
/* Ajoutez des styles personnalisés pour le slider ici */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.cincopa_fid_AYLAVKvOtwcw:hover .cincopa-play-btn {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;
  background: rgb(0, 134, 207, 0.76);
  left: 0px;
  top: 0px;
  transition: all 80ms ease-out 0s;
  mix-blend-mode: multiply;
}
.cincopa_responsive_wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* Pour les écrans dont la largeur est inférieure à 768px (mobile), la hauteur est de 100% */
@media only screen and (max-width: 768px) {
  .cincopa_responsive_wrapper {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

/* Pour les écrans dont la largeur est supérieure à 768px (PC), la hauteur est de 65% */
@media only screen and (min-width: 769px) {
  .cincopa_responsive_wrapper {
    height: 65%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slider-item {
  animation: slide 15s linear infinite;
}

@keyframes slide {
  0%,
  100% {
    transform: translateX(0%);
  }

  25% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(-200%);
  }

  75% {
    transform: translateX(-300%);
  }
}
</style>
